<template>
  <div>
    <filter-table :filter_data="filter_data"></filter-table>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BTab,
  BTabs,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import FilterTable from "../component/FilterTable.vue";
import moment from "moment";
import store from "@/store/index";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTab,
    BTabs,
    BButton,
    VueGoodTable,
    FilterTable,
  },
  data() {
    return {
      text: this.$route.params.text,
      filter_data: [],
      form: {
        side: "status",
        order_status: ["transfered"],
        selected_page: 1,
        per_page: 100,
      },
    };
  },
  created() {
    this.load_data();
  },
  methods: {
    load_data() {
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: this.form,
      }).then((x) => {
        this.filter_data = x.data.data;
      });
    },
  },
};
</script>
